import Image from "next/legacy/image";
import ContainedTextBlock from '@components/contained-text-block';
import Button from '@components/ui/button';
import Text from '@components/ui/text';
import React, { ReactElement } from 'react';
import Grid from '@components/grid';
import { ASSET_URL } from 'lib/constants';
import Container from '@components/ui/container';
import CMSLink from '@components/CMSLink';

export default function ButtonsOverlayImageHero(block: any): ReactElement {
  const { title, columns, image, buttons, quote } = block;
  return (
    <Container>
      <Grid className="pt-6 md:pt-8 lg:pt-12 xl:pt-20 gap-y-0 sm:gap-y-8">
        <div className="col-span-full md:col-span-4 lg:col-span-6">
          <Text
            variant="superHeading"
            className="font-bold max-w-sm xl:max-w-lg"
          >
            {title}
          </Text>
        </div>

        {columns.map((column: any) => {
          return (
            <ContainedTextBlock
              key={`careers-columns-map--${column.id}`}
              title={column.title}
              content={column.description}
            />
          );
        })}

        <div className="group relative mt-8 sm:mt-0 lg:mt-8 col-span-full overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-10" />
          <div className="absolute inset-8 md:top-8 lg:top-16 md:left-8 lg:left-16 md:bottom-8 lg:bottom-16 md:right-auto z-20 text-white flex flex-col justify-between items-stretch">
            {buttons.map(({ link, id }: any) => {
              return (
                <CMSLink
                  key={`link--button--${id}`}
                  {...link}
                  className="w-full"
                >
                  <Button
                    className="lg:min-w-[300px]"
                    type="button"
                    invert={true}
                    variant="cta"
                  >
                    {link.label}
                  </Button>
                </CMSLink>
              );
            })}
          </div>

          <div className="col-span-full relative min-h-[400px] lg:min-h-[500px]">
            <Image
              src={ASSET_URL + '/' + image?.filename}
              alt={image?.alt || ''}
              loading="lazy"
              className="object-cover object-top group-hover:scale-105 transition-transform duration-1000"
              layout="fill"
            />
          </div>
        </div>
        <div className="relative -mt-0.5 bg-heading col-span-full md:col-span-5 md:col-start-4 lg:col-span-6 lg:col-start-7 lg:gap-0 text-white p-6 md:p-8 lg:p-10  flex flex-col z-10">
          <div className=" mb-2 text-right">
            <span className="font-semibold leading-relaxed text-xl lg:text-2xl">
              {quote.text}
            </span>
          </div>

          <div className="flex items-center mt-2 lg:mt-4">
            <div className="relative w-12 h-12 z-20 overflow-hidden">
              <Image
                src={ASSET_URL + '/' + quote.image.filename}
                className="object-cover object-center"
                alt={image?.alt || ''}
                loading="lazy"
                layout="fill"
              />
            </div>

            <span className="text-xs ml-4 text-[#979EA6] font-medium">
              {quote.name}
            </span>
          </div>
        </div>
      </Grid>
    </Container>
  );
}
