import Button from './button';

export default function FormSubmitButton({
  isLoading = false,
  buttonText = 'SUBMIT',
  className,
  buttonClassName,
}: {
  isLoading: boolean;
  buttonText?: string;
  className?: string;
  buttonClassName?: string;
}) {
  return (
    <div className={`relative mt-8 ${className}`}>
      <Button
        type="submit"
        variant={'cta'}
        invert
        className={`h-11 md:h-12 w-full mt-0 ${buttonClassName}`}
        disabled={isLoading}
      >
        {isLoading ? 'SENDING...' : buttonText}
      </Button>
    </div>
  );
}
