import Container from '@components/ui/container';
import HeroWithBackgroundVideo from '@containers/hero-with-background-video';
import React, { ReactElement } from 'react';
import BannerCard from '@components/BannerCard';

function HeroSliderFullscreen(block: any): ReactElement {
  const { title, description, buttons, video, options } = block;
  const { terminal } = options;
  const videoUrl = `${video?.url}`

  return (
    <HeroWithBackgroundVideo
      padding={false}
      source={videoUrl}
    >
      <div className="z-30 relative overflow-hidden">
        <Container className="h-screen flex justify-center items-center">
          <BannerCard
            title={title}
            description={description}
            idx={0}
            buttons={buttons}
            terminal={terminal}
          />
        </Container>
      </div>
    </HeroWithBackgroundVideo>
  );
}

export default HeroSliderFullscreen;
