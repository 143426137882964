import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  source?: string;
  darken?: boolean;
  padding?: boolean;
}

export default function HeroWithBackgroundVideo({
  darken,
  children,
  source,
  padding = true,
}: Props): ReactElement {
  return (
    <div
      className={`min-h-screen relative bg-heading ${
        padding ? 'py-24' : 'py-0'
      } overflow-hidden`}
    >
      {darken && <div className="absolute inset-0 bg-heading"></div>}
      <div className="z-10 absolute inset-0 bg-gradient-to-b from-telesoft to-transparent mix-blend-soft-light"></div>
      <div className="absolute z-0 inset-0 opacity-50">
      <video
          id="vid"
          loop
          autoPlay
          playsInline
          muted
          title="Threat hunting network visualisation."
          preload="none"
          width="100%"
          className="object-cover"
          height="100%"
        >
          <source
            src={source}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      {children}
    </div>
  );
}
