import React from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { SERVER_BASE_URL } from '@lib/constants';

const defaultDescription =
  'Telesoft delivers technology to help you stay ahead of your competitors with critical infrastructure protection, managed services and data intelligence.';
const defaultTitle = 'Telesoft';
const titleSuffix = ' | Leading Cyber Security & Telco Provider';
const defaultOGImage = `https://stratus.on.telesoft.cloud/assets/og-image-wide.png`;
const defaultKeywords =
  'Cyber Security, Cyber Technology, Data Intelligence, Network Infrastructure';

type Props = {
  title?: string;
  description?: string;
  ogImage?: string;
  keywords?: string;
  children?: React.ReactNode;
};

export default function Head({ title, description, ogImage, keywords }: Props) {
  const { asPath } = useRouter();
  const image = ogImage ? `${SERVER_BASE_URL}${ogImage}` : defaultOGImage;

  const getTitle = () => {
    if (title) return title + titleSuffix;
    return defaultTitle;
  };

  return (
    <NextHead>
      <title>{getTitle()}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />

      <meta
        property="og:url"
        content={`https://telesoft-technologies.com/${asPath}`}
      />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:image" content={image || defaultOGImage} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:site" content="@Telesoft_Tech" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={image || defaultOGImage} />
      <meta name="theme-color" content="#fff" />
    </NextHead>
  );
}
