import React, { ReactElement } from 'react';
import Image from "next/legacy/image";
import { Tab } from '@headlessui/react';
import { motion } from 'framer-motion';

import Text from '@components/ui/text';
import Grid from '@components/grid';
import IndexText from '@components/index-text';
import LogoTabLink from '@components/logo-tab-link';
import { ASSET_URL } from 'lib/constants';
import Container from '@components/ui/container';

export type OptionType = {
  id: string;
  title: string;
  href: string;
  description: string;
  image: string;
};

export default function TabbedDiagram(block: any): ReactElement {
  const { subTitle, title, description, tabs } = block;
  return (
    <Container>
      <Grid className="relative">
        <Tab.Group>
          <Tab.List
            className="z-10 col-span-full lg:col-span-6 p-8 md:p-12 lg:p-20 bg-black"
            as="ul"
          >
            <div className="pb-4 md:mb-10">
              {subTitle && <IndexText index={subTitle} type="text" />}
              <Text variant="subHeading" className="text-white mb-4">
                {title}
              </Text>
              <Text variant="body" className="text-white opacity-50">
                {description}
              </Text>
            </div>

            {tabs.map((tab: any, i: number) => {
              const { title, link } = tab;

              return (
                <LogoTabLink
                  key={`tab-menu-map--${i}`}
                  option={title}
                  link={link}
                  onClickTab={() => {
                    document
                      .getElementById('panels')!
                      .scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }}
                  index={i}
                  label={'Explore: Products'}
                />
              );
            })}
          </Tab.List>
          <Tab.Panels className="z-10 col-span-full lg:col-span-6 bg-accent-1 p-8 md:p-12 lg:p-20">
            <div id={'panels'} />
            {tabs.map((tab: any, i: number) => {
              const { subTitle, description, image } = tab;
              return (
                <Tab.Panel
                  key={`tab-panel-map--${i}`}
                  className="flex flex-col justify-start items-start"
                >
                  <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <div className="mb-10 w-full flex justify-center items-center">
                      <Image
                        src={ASSET_URL + '/' + image.filename}
                        alt={image.alt || ''}
                        loading="lazy"
                        width={560}
                        height={340}
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start">
                      <IndexText index={`${i + 1}`} />
                      <Text variant="heading" className="mb-4">
                        {subTitle}
                      </Text>
                      <div className="h-[1px] bg-black w-full mb-7" />
                      <Text variant="body" className="">
                        {description}
                      </Text>
                    </div>
                  </motion.div>
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </Grid>
    </Container>
  );
}
