import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import dynamic from 'next/dynamic'
import CloseModalButton from '@components/close-modal-button';
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });
// TODO replace react-plater

interface Props {
    showModal: boolean;
    setShowModal: any;
    title: string;
    url: string;
}

export default function VideoModal({
    showModal = false,
    setShowModal,
    url = 'https://www.youtube.com/embed/sjkiw7OYbVY',
    title = 'Telesoft - TDAC at scale',
}: Props) {
    function closeModal() {
        setShowModal(false);
    }
    return (
        <Transition show={showModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                open={showModal}
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-90" />
                    </Transition.Child>

                    <CloseModalButton onClick={closeModal} />
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-400"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="group relative inline-block w-full h-full max-w-3xl text-left align-middle bg-white shadow-xl">
                            {/* <div className="absolute -top-10 left-0 right-0 h-[1px] bg-white max-w-[280px] group-hover:max-w-full transition-all duration:1000" /> */}
                            <div className="absolute -bottom-10 left-0 right-0 h-[1px] bg-white max-w-[280px] group-hover:max-w-full group-hover:bg-telesoft transition-all duration:1000" />
                            <div className="video-responsive">
                                <ReactPlayer title={title} url={url} />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
