import React from 'react';
import FieldLabel from './field-label';

type OptionType = {
  label: string;
  value: string | null;
};

interface Props {
  name: string;
  labelKey: string;
  options: OptionType[];
  errorKey?: string;
}

const BrowserSelect = React.forwardRef<HTMLSelectElement, Props>(
  ({ name, options, labelKey, errorKey, ...rest }, ref) => {
    return (
      <div className="">
        {labelKey && <FieldLabel name={name} labelKey={labelKey} />}
        <select
          id={name}
          name={name}
          ref={ref}
          className="bg-transparent py-3.5 pl-0 pr-4 md:pr-5 w-full appearance-none relative text-sm text-black lg:text-base border-b border-black placeholder-body min-h-12"
          aria-invalid={errorKey ? 'true' : 'false'}
          {...rest}
        >
          {options.map((option: OptionType, idx: number) => {
            return (
              <option
                key={`option--key--${option.value}`}
                selected={idx === 0 ? true : false}
                value={option.value || ''}
              >
                {option.label}
              </option>
            );
          })}
        </select>
        {errorKey && <p className="my-2 text-xs text-red-500">{errorKey}</p>}
      </div>
    );
  }
);

BrowserSelect.displayName = 'BrowserSelect';

export default BrowserSelect;
