import type { FC } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { LinkProps } from 'next/link';
import { motion } from 'framer-motion';
import Button from '@components/ui/button';
import Text from '@components/ui/text';
import Grid from '@components/grid';
import IndexText from '@components/index-text';
import { VscChevronRight } from 'react-icons/vsc';
import AnimatedLineContent from '@components/animated-line-content';

interface ArticleSliderCardProps {
  slide: any;
  variant?: 'rounded' | 'default';
  className?: string;
  idx?: number;
  terminal?: boolean;
  classNameInner?: string;
  href: LinkProps['href'];
}

const heroContentVariant = {
  initial: {
    opacity: 0,
    y: 28,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeOut',
      staggerChildren: 0.2,
      duration: 0.3,
    },
  },
};

const HeroBannerCard: FC<ArticleSliderCardProps> = ({
  slide,
  className,
  idx,
  terminal,
}) => {
  return (
    <div className={cn('mx-auto z-30 relative', className)}>
      <motion.div
        variants={heroContentVariant}
        initial="initial"
        animate="animate"
      >
        <Grid>
          <motion.div
            variants={heroContentVariant}
            className="flex flex-col justify-start items-start relative col-span-4 lg:col-span-7 text-white z-10 h-full"
          >
            {terminal && <IndexText index={idx || 0} />}
            <Text variant="superHeading" className="text-white">
              <span className="leading-snug">{slide.title}</span>
            </Text>
          </motion.div>

          <motion.div
            variants={heroContentVariant}
            className="flex flex-col justify-start relative items-start bg-opacity-50 lg:pt-4 lg:mt-4 col-span-4 lg:col-span-5 xl:col-span-5 xl:col-end-13 text-white z-10"
          >
            <AnimatedLineContent />
            <Text className="mt-4 text-white text-sm md:text-base lg:text-lg">
              {slide.description}
            </Text>
          </motion.div>

          <motion.div
            variants={heroContentVariant}
            className="col-span-full text-white md:col-span-4 md:col-start-5 lg:col-start-8 lg:col-end-13"
          >
            <Link href={`${slide.slug}`} legacyBehavior>
              <Button variant="cta" invert={true} className="w-full relative">
                <VscChevronRight className="mr-1" />
                {slide.buttonText}
              </Button>
            </Link>
          </motion.div>
        </Grid>
      </motion.div>
    </div>
  );
};

export default HeroBannerCard;
